 
<template>
  <div>
    <b-spinner small />
    กำลังออกจากระบบ......
  </div>
</template>


<script>
import { BSpinner, BButton } from "bootstrap-vue";
export default {
  components: {
    BSpinner,
    BButton,
  },

  data() {
    return {};
  },
  mounted() {
    // Remove userData from localStorage
    // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token

    localStorage.clear();
    window.location.reload();
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>